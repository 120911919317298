import React from "react";
import './cta.scss';

function CTA() {
return (
<div className="cta">
<h2>Spring 2025 Registration is open!</h2>
            <p>Registration for all team is currently open. Spots fill up quickly.</p>
            <p><a href="https://forms.gle/VXgr5iQB8ymxXFfB9">Register Today!</a></p>
            </div>

);
}

export default CTA





