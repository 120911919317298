import React from "react";
import "./banner.scss";

function Banner() {
return (

<div className="banner">
    <div className="logo">
        <img src="./assets/storm-logo.png" alt="Storm Lacrosse Logo" />
    </div>

</div>

);
}

export default Banner